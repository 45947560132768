<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-12">İadeler</div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="4">
              <CInput
                description="Başlangıç Tarihi"
                type="date"
                horizontal
                v-model="startDate"
              />
            </CCol>
            <CCol col="4">
              <CInput
                description="Bitiş Tarihi"
                type="date"
                horizontal
                v-model="endDate"
              />
            </CCol>
            <CCol col="4">
              <CButton color="success" @click="refundOrders(startDate, endDate)">Listele</CButton>&nbsp;&nbsp;
              <CButton color="primary" @click="getExcelByRefundOrders">Excel'e Aktar</CButton>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <CCard>
        <CCardBody>
          <CRow>
            <CCol col="12">
              <CDataTable
                :items="refundOrderList"
                :fields="fields"
                :loading="loading"
                :items-per-page="10"
                pagination
                hover
                clickable-rows
                v-on:refresh="refundOrders(startDate, endDate);"
              >
                <template #createTime="{item}"> 
                  <td> {{ item.createTime && dateFormat(item.createTime) }} </td>
                </template>
                <template #orderCreateTime="{item}"> 
                  <td> {{ item.orderCreateTime && dateFormat(item.orderCreateTime) }} </td>
                </template>
              </CDataTable>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>


import moment from "moment";
export default {
  name: 'RefundOrders',
  data() {
    return {
      fields: [
        {key: 'orderId', label: 'Sipariş No', _style: 'font-size:12px'},
        {key: 'orderStatus', label: 'Sipariş Durumu', _style: 'font-size:12px'},
        {key: 'orderCreateTime', label: 'Sipariş Tarihi', _style: 'font-size:12px'},
        {key: 'amount', label: 'Sipariş Tutarı', _style: 'font-size:12px'},
        {key: 'currencyCode', label: 'Döviz', _style: 'font-size:12px'},
        {key: 'paymentLogAmount', label: 'İade Tutarı', _style: 'font-size:12px'},
        {key: 'createTime', label: 'İade Tarihi', _style: 'font-size:12px'},
      ],
      startDate: '',
      endDate: '',
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    },
  },
  computed: {
    refundOrderList() {
      return this.$store.getters.orderRefunds;
    },
    loading() {
      return this.$store.getters.orderLoading;
    }
  },
  components: {},
  methods: {
    dateFormat(date){
      return moment(date).format("DD.MM.YYYY HH:mm:ss")
    },

    async refundOrders(startDate, endDate) {
      let params = {
        "startDate":  moment(startDate).format('YYYY-MM-DDTHH:mm:ss:SSZ'),
        "endDate": moment(endDate).format('YYYY-MM-DDTHH:mm:ss:SSZ'),
      }
      await this.$store.dispatch('order_refunds', params)
    },

    async getExcelByRefundOrders() {
      await this.$store.dispatch('order_refundExcel', {responseType: 'blob'})
      if(this.$store.getters.orderStatus){
        const url = window.URL.createObjectURL(new Blob([this.$store.getters.orderStatus]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'İadeler_' + moment(new Date).format('DD/MM/YYYY HH:mm:ss') + '.xls');
        document.body.appendChild(link);
        link.click();
      }
    }
  },
  created() {
    var today = new Date();
    this.endDate = moment(today).format('YYYY-MM-DD');
    this.startDate = moment().subtract(1,"month").format('YYYY-MM-DD');
    this.refundOrders(this.startDate, this.endDate);
  }
}
</script>
